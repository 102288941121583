import { useState } from "react";
import "./MycrosLogin.css";

export default function MycrosLogin() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <div className="main">
      <h1>
        Mycros Hesabınla <br />
        Giriş Yap
      </h1>
    </div>
  );
}
