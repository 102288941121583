import "./MycrosAppLayout.css";
import logo from "../images/mycrosLogo.svg";

export default function MycrosAppLayout({ content = content }) {
  return (
    <div className="body-file-uploader">
      <div className="header">
        <div className="main-row-flex-container">
          MY<strong>CROS</strong>
        </div>
      </div>
      <div className="mycros-body">{content}</div>
      <div className="logo">
        <img src={logo}></img>
      </div>
    </div>
  );
}
