import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import AppLayout from "@awsui/components-react/app-layout";
import FormField from "@awsui/components-react/form-field";
import Alert from "@awsui/components-react/alert";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SideNavigation from "@awsui/components-react/side-navigation";
import Button from "@awsui/components-react/button";
import TokenGroup from "@awsui/components-react/token-group";
import TopNavigation from "@awsui/components-react/top-navigation";
import SpaceBetween from "@awsui/components-react/space-between";
import ProgressBar from "@awsui/components-react/progress-bar";
import Amplify, { Auth, Storage } from "aws-amplify";
import {
  Authenticator,
  Heading,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import AWS from "aws-sdk";
import MycrosAppLayout from "./MycrosAppLayout/MycrosAppLayout";
import MycrosLogin from "./MycrosLogin/MycrosLogin";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_identityPoolId, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_region, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_userPoolId, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId, //OPTIONAL - Amazon Cognito Web Client ID
  },
});

const S3_BUCKET = process.env.REACT_APP_bucketName;
const REGION = process.env.REACT_APP_region;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const appLayoutLabels = {
  navigation: "Side navigation",
  navigationToggle: "Open side navigation",
  navigationClose: "Close side navigation",
  notifications: "Notifications",
  tools: "Help panel",
  toolsToggle: "Open help panel",
  toolsClose: "Close help panel",
};

const queryParams = new URLSearchParams(window.location.search);
const productRequest = queryParams.get("productRequest");
var path = queryParams.get("path");
const fileName = queryParams.get("fileName");
const brandName = queryParams.get("brandName");
const productName = queryParams.get("productName");

function formatBytes(a, b = 2, k = 1024) {
  let d = Math.floor(Math.log(a) / Math.log(k));
  return 0 === a
    ? "0 Bytes"
    : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
        " " +
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

const Content = ({ brandName, productName }) => {
  const hiddenFileInput = useRef(null);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [uploadList, setUploadList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [resultText, setResultText] = useState("");
  const handleClick = () => {
    hiddenFileInput.current.value = ""; // This avoids errors when selecting the same files multiple times
    hiddenFileInput.current.click();
  };
  const handleChange = (e) => {
    e.preventDefault();
    let i,
      tempUploadList = [];
    for (i = 0; i < e.target.files.length; i++) {
      tempUploadList.push({
        label: e.target.files[i].name,
        labelTag: formatBytes(e.target.files[i].size),
        description: "Dosya tipi: " + e.target.files[i].type,
        icon: "file",
        id: i,
      });
    }
    setUploadList(tempUploadList);
    setFileList(e.target.files);
  };

  function progressBarFactory(fileObject, percentage, id) {
    const localHistory = [...historyList];

    if (localHistory[id]) {
      localHistory[id]["percentage"] = percentage;

      if (localHistory[id]["percentage"] == 100) {
        localHistory[id]["status"] = "success";
      }
    } else {
      let history = {
        id: id,
        percentage: percentage == undefined ? 0 : percentage,
        filename: fileObject.name,
        filetype: fileObject.type,
        filesize: formatBytes(fileObject.size),
        status: percentage == 100 ? "success" : "in-progress",
      };
      localHistory.push(history);
    }

    console.log(localHistory);
    setHistoryList(localHistory);
  }

  async function uploadFile(fileName, file, callback) {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: fileName,
    };

    return await myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        callback(null, Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          return callback(err);
        }
        return callback(null, 100);
      });
  }

  function isVideo(file) {
    if (!(file instanceof File)) {
      throw new Error("Input must be a File object");
    }

    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const videoExtensions = ["mp4", "mov", "avi", "mkv"];

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (imageExtensions.includes(fileExtension)) {
      return false;
    } else if (videoExtensions.includes(fileExtension)) {
      return true;
    } else {
      throw new Error("File type not supported. Must be an image or a video.");
    }
  }

  const handleUpload = () => {
    if (uploadList.length === 0) {
      setVisibleAlert(true);
    } else {
      console.log("Uploading files to S3");
      let i,
        progressBar = [],
        uploadCompleted = [];
      for (i = 0; i < uploadList.length; i++) {
        // If the user has removed some items from the Upload list, we need to correctly reference the file
        const id = uploadList[i].id;
        progressBar.push(progressBarFactory(fileList[id], undefined, id));
        setHistoryCount(historyCount + 1);

        if (isVideo(fileList[id])) {
          path += "video/";
        } else {
          path += "photo/";
        }

        uploadCompleted.push(
          uploadFile(path + fileName, fileList[id], (error, progress) => {
            progressBarFactory(fileList[id], progress, id);
          }).then((result) => {
            // Trying to remove items from the upload list as they complete. Maybe not work correctly
            // setUploadList(uploadList.filter(item => item.label !== result.key));
            console.log("Completed the upload of " + result);
            setResultText(
              "Dosya başarıyla yüklendi, lütfen mycros.io adresinden 'Markaya Gönder' butonuna basınız"
            );
          })
        );
      }
      // When you finish the loop, all items should be removed from the upload list
      Promise.all(uploadCompleted).then(() => setUploadList([]));
    }
  };

  const handleDismiss = (itemIndex) => {
    setUploadList([
      ...uploadList.slice(0, itemIndex),
      ...uploadList.slice(itemIndex + 1),
    ]);
  };

  const List = ({ list }) => (
    <>
      {list.map((item) => (
        <ProgressBar
          key={item.id}
          status={item.status}
          value={item.percentage}
          variant="standalone"
          additionalInfo={item.filesize}
          description={item.filetype}
          label={item.filename}
          resultText={resultText}
        />
      ))}
    </>
  );
  return (
    <SpaceBetween size="l">
      <Container
        id="s3-upload-multiple-objects"
        header={
          <Header variant="h2">
            {brandName} - {productName}
          </Header>
        }
      >
        {
          <div>
            <Alert
              onDismiss={() => setVisibleAlert(false)}
              visible={visibleAlert}
              dismissAriaLabel="Close alert"
              dismissible
              type="hata"
              header="Dosya Seçilmedi"
            >
              Yüklemek istediğiniz dosyayı lütfen seçiniz
            </Alert>

            <FormField
              label="Ürün yükleme"
              description="İçeriği seç butonuna tıklayarak ürettiğin örnek içeriği veya içerikleri seç"
            />

            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={handleClick} iconAlign="left" iconName="upload">
                İçeriği Seç
              </Button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <Button variant="primary" onClick={handleUpload}>
                Yükle
              </Button>
            </SpaceBetween>

            <TokenGroup
              onDismiss={({ detail: { itemIndex } }) => {
                handleDismiss(itemIndex);
              }}
              items={uploadList}
              alignment="vertical"
              limit={10}
            />
          </div>
        }
      </Container>
      <Container
        id="history"
        header={<Header variant="h2">Yüklenenler</Header>}
      >
        <List list={historyList} />
      </Container>
    </SpaceBetween>
  );
};

const components = {
  SignIn: {
    Header() {
      return <MycrosLogin />;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "E-mailiniz giriniz",
    },
    password: {
      placeholder: "Lütfen Mycros şifrenizi giriniz",
    },
  },
};

function App() {
  const [navigationOpen, setNavigationOpen] = useState(true);
  const navbarItemClick = (e) => {
    console.log(e);
    if (e.detail.id === "signout") {
      Auth.signOut().then(() => {
        window.location.reload();
      });
    }
  };

  if (!brandName || !productName || !path || !fileName) {
    return "Access Denied to the website";
  } else {
    return (
      <MycrosAppLayout
        content={
          <Authenticator formFields={formFields} components={components}>
            <div className="content">
              <Content brandName={brandName} productName={productName} />
            </div>
          </Authenticator>
        }
      />
    );
  }
}

export default App;
